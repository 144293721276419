<template>
    <section class="">
        <front-table :front_page="page"
                     :front_records="records"
                     :front_search="search"
                     :front_header="header"
                     :model="model"
                     @reload="handleReload"
                     @review="handleReview"
                     :btn_add="false"
                     :btn_delete="false"
                     :btn_edit="false"
                     :btn_review="true"
        >
        </front-table>
        <!--编辑界面-->
        <log-view :single="single" :view_mode="view_mode" @cancel="view_mode = false" mobile_mode v-mobile-dialog>
        </log-view>

    </section>
</template>

<script>
    import FrontTable from "../../components/front-mobile-table";
    import FrontMixins from "../../common/mixins/front-mixins";
    import LogView from "../../components/log-view";

    export default {
        mixins: [FrontMixins],
        name: 'admin-admin',
        components: {LogView, FrontTable},
        created() {
            this.model = 'Admin.' + this.$route.meta.model;
        }

    }

</script>

<style lang="scss">

</style>
